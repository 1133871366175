<template>
    <div class="action-dropout">
        <div class="title f-bold" v-html="$translate('ACTION_NOTICE_DROPOUT_TITLE')" />
        <div class="body">
            <div v-html="body" />
        </div>
    </div>
</template>
<script>
export default {
    name: 'ActionDropout',
    props: ['message'],
    computed: {
        chat() {
            return this.$store.getters.chat
        },
        chatUser() {
            if (!this.chat) return

            return (this.chat || {}).user
        },
        body() {
            const reasons = this.initReasons()
            const parsedMessage = JSON.parse(this.message.content)
            const reasonIds = parsedMessage.inactive_reason_ids
            const selectedReasons = reasons.filter(reason => reasonIds.includes(reason.id))
            const reasonTexts = selectedReasons.map(reason => reason.value)

            if (parsedMessage.inactive_reason_etc) {
                reasonTexts.push(parsedMessage.inactive_reason_etc)
            }

            const body = reasonTexts.join(', ')

            return body
        },
    },
    methods: {
        initReasons() {
            const reasons = [
                { id: 1, value: '교제를 시작함(굿퍼슨을 통해)' },
                { id: 2, value: '교제를 시작함(다른 루트를 통해)' },
                { id: 3, value: '가격이 비쌈' },
                { id: 4, value: '개인정보 / 프라이버시 문제' },
                { id: 5, value: '매칭이 잘 안됨' },
                { id: 6, value: '풀 퀄리티가 좋지 않음(외모)' },
                { id: 7, value: '풀 쿼리티가 좋지 않음(스펙)' },
                { id: 8, value: '매니저가 내 니즈를 잘 반영하지 못함' },
                { id: 9, value: '프로필 숫자가 적음 / 주기가 긺' },
                { id: 10, value: '약속이 자주 변경/취소됨' },
                { id: 11, value: '가벼운 사람이 많음(원나잇)' },
                { id: 12, value: '진정성이 떨어짐' },
                { id: 13, value: '프로필 정보가 제한적임' },
                { id: 14, value: '어플 오류 / 앱 사용이 불편함' },
                { id: 15, value: '서비스 이용 방법이 어려움' },
                { id: 16, value: '기타' },
            ]
            return reasons
        },
    },
}
</script>
